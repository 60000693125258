import React, { useEffect, useState } from 'react'
import { getData } from '../../api/Api'
import useData from '../../hooks/useData'
import './abouts.css'

import decoracion from '../../assets/arrows.svg'

function Abouts() {
  const { updateAbout } = useData()
  const [abouts, setAbouts] = useState([])

  useEffect(() => {
    ; (async () => {
      try {
        const res = await getData('acercade')
        setAbouts(res.records.reverse())
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    if (abouts.length > 0) {
      updateAbout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abouts])
  return (
    <>
      {abouts.length > 0 && (
        <div className="abouts" id="acercade">
          <div className="container">
            <div className="abouts__content is-flex is-justify-content-center ">
              <div className="abouts__header is-flex is-flex-direction-column is-align-items-center">
                <p className="abouts__header-subtitle is-size-3 is-size-6-mobile has-text-centered has-text-weight-bold mt-2 pb-5">
                  Agregamos valor a tu empresa a través de tecnología emergente,
                  proporcionando soluciones integrales.
                </p>
                <h3 className="abouts__header-title title is-4 m-0 is-hidden">
                  Quienes somos
                </h3>
                <img src={decoracion} alt="/\/\/\" className='is-hidden' />
              </div>
            </div>

            {abouts.map(item => {
              return (
                <div className="abouts__items" key={item.ID_CONTENIDO}
                  dangerouslySetInnerHTML={{ __html: item.CONTENIDO }}
                />
              )
            })}

          </div>
        </div>
      )}
    </>
  )
}

export default Abouts
